import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { List, ListInvitee } from '../models/app.models';
import { Observable, catchError, map, throwError } from 'rxjs';
import { ImportResult } from 'src/app/features/crm/models/import-result.interface';

@Injectable({
  providedIn: 'root',
})
export class ListService {
  constructor(private http: HttpClient) {}

  importCsv(file: Blob, listId: number): Observable<ImportResult> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<ImportResult>(
      environment.api + `/listInvitee/import/csv/${listId}`,
      formData,
    );
  }

  exportCsvByListId(listId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(
      environment.api + '/listInvitee/export/csv/' + listId,
      {
        responseType: 'blob',
        observe: 'response',
      },
    );
  }

  getCsvTemplate(): Observable<HttpResponse<Blob>> {
    return this.http.get(environment.api + '/listInvitee/template/csv', {
      responseType: 'blob',
      observe: 'response',
    });
  }

  createList(list: List): Observable<any> {
    return this.http.post(environment.api + '/list', list).pipe(
      map((response: any) => response.id),
      catchError((error: HttpErrorResponse) =>
        throwError(
          () =>
            new Error(
              `Something bad happened; please try again later. Http-Response: ${error.status}`,
            ),
        ),
      ),
    );
  }

  updateList(list: List) {
    return this.http
      .put(environment.api + '/list/update', list)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }

  createNewLinkForList(list: List) {
    return this.http.put(environment.api + '/list/newLink', list).pipe(
      map((response: any) => response.id),
      catchError((error: HttpErrorResponse) =>
        throwError(
          () =>
            new Error(
              `Something bad happened; please try again later. Http-Response: ${error.status}`,
            ),
        ),
      ),
    );
  }

  deleteList(listId: number) {
    return this.http
      .delete(environment.api + `/list/${listId}`)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }

  getAllListsFromEvent(eventId: number | null): Observable<List[]> {
    return this.http.get<List[]>(environment.api + '/list/all/' + eventId);
  }

  getListByUuid(uuid: string | null): Observable<List> {
    return this.http.get<List>(environment.api + '/list/link/' + uuid);
  }

  getListById(id: number | null): Observable<List> {
    return this.http.get<List>(environment.api + '/list/' + id);
  }

  getListsByEventId(eventId: number): Observable<List[]> {
    return this.http.get<List[]>(environment.api + '/list/event/' + eventId);
  }

  getListInviteesByListId(listId: number | null): Observable<ListInvitee[]> {
    return this.http.get<ListInvitee[]>(
      environment.api + '/listInvitee/' + listId,
    );
  }

  getAllInviteesFromEvent(eventId: number): Observable<ListInvitee[]> {
    return this.http.get<ListInvitee[]>(
      environment.api + '/listInvitee/all/' + eventId,
    );
  }

  addInviteeToList(listInvitee: ListInvitee) {
    return this.http
      .post(environment.api + '/listInvitee', listInvitee)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }

  editInviteeToList(listInvitee: ListInvitee) {
    return this.http
      .put(environment.api + '/listInvitee/update', listInvitee)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }

  checkInOutInvitee(listInvitee: ListInvitee) {
    return this.http
      .put(environment.api + '/listInvitee/checkIn', listInvitee)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }

  deleteInviteeFromList(inviteeId: number) {
    return this.http
      .delete(environment.api + `/listInvitee/${inviteeId}`)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          throwError(
            () =>
              new Error(
                `Something bad happened; please try again later. Http-Response: ${error.status}`,
              ),
          ),
        ),
      );
  }
}
